import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";


export const postStateUpdate = createAsyncThunk("postStateUpdate", async (stateData: any) => {
    const { data } = await axios.patch(
        `${GATEWAY_BASE}/core-backend/v1/api/v1/state/update`,
        stateData
    );
    return data;
});

const postStateUpdateSlice = createSlice({
    name: "postStateUpdate",
    initialState: {
        loading: false,
        success: false,
        stateToken: null,
        error: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postStateUpdate.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(postStateUpdate.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.stateToken = action.payload;
        });
        builder.addCase(postStateUpdate.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default postStateUpdateSlice.reducer;
