import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { reset, userSignin } from "../redux/slices/userSlices/userSigninSlice";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Icon } from "@iconify/react";

import OTPInput from "react-otp-input";

import EcnButton from "../components/StyledComponents/EcnButton";
import EcnInput from "../components/StyledComponents/EcnInput";

import layoutOne from "../layouts/layoutOne";

import { tfaSendMobileOtp } from "../redux/slices/tfaSlices/tfaSendMobileOtpSlice";
import {
  smsLoginReset,
  tfaSmsLogin,
} from "../redux/slices/tfaSlices/tfaSmsLoginSlice";
import {
  authAppLoginReset,
  tfaAuthAppLogin,
} from "../redux/slices/tfaSlices/tfaAuthAppLoginSlice";
import {
  arrayBufferToUrlSafeBase64,
  base64ToArrayBuffer,
} from "../helpers/passkeyFunctions";

import axios from "../redux/axiosInterceptor";
import EcnSnackbar from "../components/StyledComponents/EcnSnackbar";
import {
  isValidOrigin,
  loginFirm,
  loginRedirectNetwork,
} from "../helpers/TempData";
import { userSocialLogin } from "../redux/slices/userSlices/userSocialLoginSlice";
import { getStateData } from "../redux/slices/userSlices/getStateDataSlice";
import { postStateUpdate } from "../redux/slices/userSlices/postStateUpdateSlice";

const SIGNUP_URL = process.env.REACT_APP_SIGNUP_REDIRECT_URL;

interface PublicKeyCredentialDescriptor {
  id: ArrayBuffer;
  type: string;
  transports?: string[];
}

interface PublicKeyCredentialRequestOptionsTS {
  challenge: ArrayBuffer;
  allowCredentials?: PublicKeyCredentialDescriptor[];
  // Include other necessary properties here
}

const convertStartResponseToPublicKeyCredentialRequestOptions = (
  data: any
): PublicKeyCredentialRequestOptionsTS => {
  return {
    ...data,
    challenge: base64ToArrayBuffer(data.challenge),
    allowCredentials: data.allowCredentials?.map((cred: any) => ({
      ...cred,
      id: base64ToArrayBuffer(cred.id),
    })),
  };
};

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

const Signin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin") || "";
  const session_id = searchParams?.get("session_id") || "";
  const state_type = searchParams?.get("state") || "";
  const errorMessage = searchParams?.get("error") || "";
  let url: URL | undefined;
  if (origin?.trim() !== "") {
    url = new URL(origin);
  }

  const {
    signinResponse,
    error,
    loading: signinLoading,
  } = useAppSelector((state) => state.userSignin);
  const {
    userInfo,
    loading,
    error: userError,
  } = useAppSelector((state) => state.userDetails);
  const {
    tfaSmsLoginVerifyResponse,
    error: mobileError,
    loading: mobileLoading,
    errorData: mobileErrorData,
  } = useAppSelector((state) => state.tfaSmsLogin);
  const {
    tfaAuthAppLoginVerifyResponse,
    error: authAppError,
    loading: authAppLoading,
    errorData: authAppErrorData,
  } = useAppSelector((state) => state.tfaAuthAppLogin);
  const { tfaSendOtpResponse } = useAppSelector(
    (state) => state.tfaSendMobileOtp
  );
  const { login, success } = useAppSelector(
    (state) => state.userSocialLoginData
  );

  const { stateData } = useAppSelector(
    (state) => state.getStateDatas
  );

  const [activeStep, setActiveStep] = useState(0); // [0, 1, 2]
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendTime, setResendTime] = useState(0);
  const [tfaView, setTfaView] = useState("");
  const [passkeyError, setPasskeyError] = useState("");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    let intervalId: any;

    if (resendTime > 0) {
      intervalId = setInterval(() => {
        setResendTime((prev) => (prev === 0 ? 0 : prev - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [resendTime]);

  useEffect(() => {
    if (!userInfo) dispatch(userDetails());
    else if (userInfo && origin) window.location.href = origin;
    else if (userInfo && !origin) {
      setTouched(false);
      navigate("/dashboard");
    }
  }, [userInfo, touched]);

  useEffect(() => {
    if (signinResponse || success) {
      dispatch(getStateData())
    }

  }, [signinResponse, success])

  useEffect(() => {
    if (stateData) {
      dispatch(
        postStateUpdate({
          data: {
            NumberOfTimeUserLogin: (stateData?.data?.NumberOfTimeUserLogin || 0) + 1
          },
        })
      );
    }
  }, [stateData, dispatch]);


  useEffect(() => {
    if (signinResponse && !signinResponse.two_factor_auth_enabled) {
      if (origin) {
        setTimeout(() => {
          window.location.href = origin;
        }, 1000);

        //navigate("/tfa?redirect-url=" + origin);
      }
      else {
        //navigate("/tfa);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    } else if (
      signinResponse &&
      signinResponse.two_factor_auth_enabled &&
      signinResponse.authenticator_app_verified
    ) {
      setActiveStep(2);
      setTfaView("auth-app");
    } else if (
      signinResponse &&
      signinResponse.two_factor_auth_enabled &&
      signinResponse.mobile_number_verified
    ) {
      dispatch(tfaSendMobileOtp(signinResponse.session_id));
      setActiveStep(2);
      setTfaView("sms");
    }
  }, [signinResponse, origin]);

  useEffect(() => {
    if (origin) {
      localStorage.setItem("redirect_origin", origin);
    }
  }, [origin]);

  useEffect(() => {
    if (session_id != "") {
      dispatch(userSocialLogin(session_id));
    }
  }, [session_id]);

  useEffect(() => {
    if (success && state_type?.trim() !== "") {

      const normalizedState = state_type?.replace("++", "").trim();
      if (normalizedState === "b2c_signin") {
        const url = loginRedirectNetwork(GATEWAY_BASE);
        setTimeout(() => {
          window.location.href = localStorage.getItem("redirect_origin") || url;
        }, 1000);
      } else if (normalizedState === "b2b_signin") {
        const url = loginFirm(GATEWAY_BASE);
        setTimeout(() => {
          window.location.href = localStorage.getItem("redirect_origin") || url;
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    }
  }, [success, state_type]);

  const backHandler = () => {
    if (activeStep === 1) dispatch(reset());
    if (activeStep === 2) dispatch(reset());
    dispatch(smsLoginReset());
    dispatch(authAppLoginReset());
    setOtp("");
    setActiveStep(0);
    setPassword("");
  };

  const signinHandler = () => {
    dispatch(userSignin({ email, password, enterprise: true }));
  };

  const otpChangeHandler = (otp: string) => {
    const upOtp = otp.toUpperCase();
    setOtp(upOtp);
    if (upOtp.length === 6)
      if (tfaView === "sms")
        dispatch(
          tfaSmsLogin({ otp: upOtp, session_id: signinResponse?.session_id })
        );
      else if (tfaView === "auth-app")
        dispatch(
          tfaAuthAppLogin({
            otp: upOtp,
            session_id: signinResponse?.session_id,
          })
        );
  };

  const resendOtpHandler = () => {
    dispatch(tfaSendMobileOtp(signinResponse?.session_id));
    setResendTime(60);
  };

  const otherWaysHandler = () => {
    setOtp("");
    setTfaView("");
    setActiveStep(3);
  };

  const otherStepSelectHandler = (flow: string) => {
    if (flow === "sms") {
      dispatch(tfaSendMobileOtp(signinResponse.session_id));
    }
    setTimeout(() => {
      setTfaView(flow);
      setActiveStep(2);
    }, 250);
  };

  const navigateHandler = () => {
    if (origin) {
      window.location.href = origin;
    } else {
      navigate("/dashboard");
    }
  };

  // Passkey
  const passkeySigninHandler = async () => {
    if (window.PublicKeyCredential) {
      try {
        // Assume setPasskeyError is defined elsewhere in your component
        setPasskeyError("");
        const startResponse = await axios.post(
          `${GATEWAY_BASE + AUTH_BASE}/v1/startPasskeyLogin`,
          {
            enterprise: true,
            rpid: window.location.hostname,
            rporigin: window.location.origin,
          }
        );

        // Convert received data into the correct format for navigator.credentials.get
        const publicKeyCredentialRequestOptions: any =
          convertStartResponseToPublicKeyCredentialRequestOptions(
            startResponse.data.publicKey
          );
        let sessionID = startResponse.data.sessionID;

        const assertion: any = await navigator.credentials.get({
          publicKey: publicKeyCredentialRequestOptions,
        });

        if (!assertion) throw new Error("Assertion failed");
        // Prepare data for sending back to the server
        // Ensure you handle the conversion from ArrayBuffer to base64 string for necessary fields
        const assertionResponseData = {
          id: assertion.id,
          type: assertion.type,
          rawId: arrayBufferToUrlSafeBase64(assertion.rawId),
          response: {
            clientDataJSON: arrayBufferToUrlSafeBase64(
              assertion.response.clientDataJSON
            ),
            authenticatorData: arrayBufferToUrlSafeBase64(
              assertion.response.authenticatorData
            ),
            signature: arrayBufferToUrlSafeBase64(assertion.response.signature),
            userHandle: assertion.response.userHandle
              ? arrayBufferToUrlSafeBase64(assertion.response.userHandle)
              : null,
          },
          email: email,
          sessionID: sessionID,
          enterprise: true,
          rpid: window.location.hostname,
          rporigin: window.location.origin,
        };

        // Send the assertion to the server for verification
        await axios.post(
          `${GATEWAY_BASE + AUTH_BASE}/v1/completePasskeyLogin`,
          assertionResponseData
        );

        dispatch(userDetails());
        //alert("Login successful!");
      } catch (err) {
        console.error(err);
        // Assume setPasskeyError is defined elsewhere in your component
        setPasskeyError("Login failed. Please try again.");
      }
    } else {
      alert(
        "Passkeys aren't supported on this browser. Please use a different browser or device or use password to sign in."
      );
    }
  };


  const capitalize = (str: any) => {
    return str
      .split('-') // Split the string into an array by the dash
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words back with dashes
  };

  console.log("stateData", stateData)

  if (loading)
    return (
      <div className="forgot-password">
        <CircularProgress style={{ marginTop: "25%" }} />
      </div>
    );

  return (
    <div className="signin">
      {session_id === "" ? (
        <div className="container">
          {/* <div className="branding">
          <div className="old">
            <img className="old-logo" src="/logo.png" alt="" />
            <p className="sub-head">is now part of</p>
          </div>
          <div className="new">
            <img className="ecn-logo" src="/one_logo.png" alt="Elevate" />
            <p className="sub">One Financial Network</p>
          </div>
        </div> */}

          <div className="email-step">
            <div className="card">
              <h3>Log In To Your Account</h3>
              <div className="field">
                <label htmlFor="">Email</label>
                <EcnInput
                  placeholder="Enter email address"
                  className="ecn-input signin-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && email !== "" && setActiveStep(1)
                  }
                  InputProps={{
                    autoComplete: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          icon="lucide:mail"
                          height={20}
                          width={20}
                          color="black"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="field">
                <div className="head">
                  <label htmlFor="">Password</label>
                  <Link
                    to={`/forgot-password?origin=${origin}`}
                    className="forgot-pass"
                  >
                    Forgot password?
                  </Link>
                </div>
                <EcnInput
                  type={showPass ? "text" : "password"}
                  placeholder="Enter password"
                  className="ecn-input signin-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && password !== "" && signinHandler()
                  }
                  error={error}
                  helperText={
                    error ? (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ba0000",
                          marginTop: "2px",
                        }}
                      >
                        Wrong email address or password. Try Again
                      </p>
                    ) : null
                  }
                  InputProps={{
                    autoComplete: "password",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          icon="lucide:lock-keyhole"
                          height={20}
                          width={20}
                          color="black"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass(!showPass)}>
                          <Icon
                            icon={showPass ? "lucide:eye" : "lucide:eye-off"}
                            height={24}
                            width={24}
                            color="black"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="next-button">
                <Button
                  className={`btn-contained signin-next_btn ${password === "" || email === "" || signinLoading
                    ? "button-disable"
                    : ""
                    }`}
                  variant="contained"
                  disabled={password === "" || email === "" || signinLoading}
                  onClick={signinHandler}
                >
                  Log in{" "}
                  {!signinLoading && (
                    <Icon
                      icon="lucide:arrow-right"
                      className="right-icon"
                      width={18}
                      height={18}
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                    />
                  )}
                  {signinLoading && (
                    <CircularProgress
                      style={{ color: "#fff", marginLeft: "10px" }}
                      size={15}
                    />
                  )}
                </Button>
              </div>

              <p style={{ textAlign: "center", marginTop: "20px", color: "red" }}>{capitalize(errorMessage)}</p>

              <p className="create">
                Don’t have a account?{" "}
                <span
                  onClick={() =>
                    navigate(
                      `/signup${origin === "" ? "" : `?origin=${origin}`}`
                    )
                  }
                >
                  Create Account{" "}
                </span>{" "}
              </p>

              <div className="signup-with-google-and-microsoft">
                <div className="con">
                  <p>Or Continue with</p>
                </div>
                <div className="google-micro-button-container">
                  <div className="google-micro-button">
                    <div className="google">

                      <Button onClick={() => window.open(`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=986683004214-4j74ama78t15qvqeu4pudr2020omh6be.apps.googleusercontent.com&scope=email%20profile&access_type=offline&prompt=consent&state=
  ${isValidOrigin(url?.origin) ? "b2b_signin" : "b2c_signin"
                        }&redirect_uri=${GATEWAY_BASE}/auth/v1/v1/google/login
  `, "_self")}>
                        <img src="https://s1.ecnstatic.com/ecn/images/common/Google.png" />{" "}
                        Google
                      </Button>

                    </div>
                    <div className="google">

                      <Button onClick={() => window.open(`
  https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7dc3715c-6148-414e-a4a2-73da0f8d9419&response_type=code&redirect_uri=${GATEWAY_BASE}/auth/v1/v1/microsoft/login&state=${isValidOrigin(url?.origin) ? "b2b_signin" : "b2c_signin"
                        }&response_mode=query&scope=user.read%20openid%20profile%20email`, "_self")}>
                        {" "}
                        <img src="https://ecn.blob.core.windows.net/ecn/images/common/Microsoft.png" />
                        Microsoft
                      </Button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="forgot-password">
          <CircularProgress style={{ marginTop: "20%" }} />
        </div>
      )
      }

      <EcnSnackbar
        open={passkeyError !== ""}
        onClose={() => setPasskeyError("")}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className="ecn-snackbar"
      >
        <Alert
          severity="error"
          onClose={() => setPasskeyError("")}
          iconMapping={{
            success: <Icon icon="lucide:x-circle" />,
          }}
        >
          <AlertTitle className="title-text">Signin Failed</AlertTitle>
          Something's not right. Retry or try other passkeys
        </Alert>
      </EcnSnackbar>
    </div >
  );
};

export default layoutOne(Signin);
