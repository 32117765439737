import userDetailsSlice from "./userDetailsSlice";
import userForgotPasswordSlice from "./userForgotPasswordSlice";
import userResetPasswordSlice from "./userResetPasswordSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";
import signUpSlice from "./signUpSlice";
import verifyOtpSlice from "./verifyOtpSlice";
import postBasicDetailsSlice from "./postBasicDetailsSlice";
import acceptInviteSlice from "./acceptInviteSlice";
import userSocialLoginSlice from "./userSocialLoginSlice";
import getStateDataSlice from "./getStateDataSlice";
import postStateUpdateSlice from "./postStateUpdateSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  signOut: userSignoutSlice,
  userSignin: userSigninSlice,
  forgotPassword: userForgotPasswordSlice,
  resetPassword: userResetPasswordSlice,
  signUpData: signUpSlice,
  verifyOtps: verifyOtpSlice,
  postBasicDetails: postBasicDetailsSlice,
  acceptInvite: acceptInviteSlice,
  userSocialLoginData: userSocialLoginSlice,
  postStateUpdates: postStateUpdateSlice,
  getStateDatas: getStateDataSlice
};
