import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const getStateData = createAsyncThunk("getStateData", async () => {
    const { data } = await axios.get(`${GATEWAY_BASE}/core-backend/v1/api/v1/state`);
    return data;
});

const getStateDataSlice = createSlice({
    name: "getStateData",
    initialState: {
        loading: false,
        stateData: null as any,
        error: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStateData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getStateData.fulfilled, (state, action) => {
            state.loading = false;
            state.stateData = action.payload;
        });
        builder.addCase(getStateData.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default getStateDataSlice.reducer;
